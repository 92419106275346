import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import GVDLogo from '../assets/client-logos/gvd'
import HouseIdeaLogo from '../assets/client-logos/houseidea'
import USQLogo from '../assets/client-logos/usq'
import { Check } from 'react-feather'
import ContactForm from '../components/contact-form'
import { CheckCircleIcon } from '@heroicons/react/solid'

const LogoWrapper = styled.div.attrs({
  className: 'flex items-center py-6 lg:py-0 px-6 md:w-full w-1/3',
})`
  width: 200px;
`
const TrustedLogosSection = styled.div.attrs({
  className: 'flex items-center py-8',
})`
  path {
    fill: #bac1cd !important;
  }
`
let checkMarkList = [
  'Business Evaluation', 
  'Marketing Consultation',
  'Professional Audit and Proposal'
]

const ContactUs = ({location}) => (
  <Layout location={location}>
    <SEO title="Work with WebPerfex" description="Looking to get in touch with WebPerfex? Our Contact Us page is the place to be! Reach out to our team for web design and digital marketing solutions. Contact us now." />

    <div class="relative bg-white">
      <div class="absolute inset-0">
        <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
      </div>
      <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div class="bg-gray-50 py-12 md:py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div class="max-w-lg mx-auto">
            <h2 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Let's grow your business.
            </h2>
            <p class="mt-3 text-lg leading-6 text-gray-500">
              We can create a custom package to meet your needs, whether it’s
              website design, search engine optimization, online marketing or a
              bit of everything.
            </p>

            <ul className="py-12 hidden md:block">

            {checkMarkList.map((x, i) => (
                <span key={i} className="py-3 flex  items-center text-white font-semibold">
                  <CheckCircleIcon   className="h-6 w-6 mr-2 text-green-500 rounded-full" />
                   
                  <span className={`text-lg text-gray-800`}>{x}</span>
                </span>
              ))} 
 
            </ul>

            <div className="hidden md:block">
              <h3 className="text-lg md:text-xl font-semibold leading-tight">
                You’re in good company
              </h3>
              <p>
                Top brands and small businesses trust WebPerfex to develop,
                market and manage their online presence.
              </p>

              <div className="flex items-center space-x-6 mt-6"> 
<div className="w-1/3">
<USQLogo fill={"#bac1cd"} />
  </div>
              
  <div className="w-1/3"><HouseIdeaLogo fill={"#bac1cd"} />  </div>
  <div className="w-1/3"> <GVDLogo fill={"#bac1cd"} />  </div>
              </div>

               
            </div>
          </div>
        </div>
        <div class="bg-white py-8 md:py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div class="max-w-lg mx-auto lg:max-w-none">
            <ContactForm showAdvancedFields={true} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactUs
